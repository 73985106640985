
.App {
  margin-left: 5px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.Nav-header{
  min-height: 30px;
  display: inline;
  background-color: white;
  border: 3 px solid black;
}

.App-header {
  background-color: #282c34;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.menu > ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline;
  position: inline; /* Make it stick, even on scroll */
  overflow: auto; /* Enable scrolling if the sidenav has too much content */
  padding-bottom: 15em;
}

.menu > ul > li{
  display: inline;
  padding-top: 5em;
  padding-bottom: 5em;
  padding-left: 5em;
  padding-right: 5em;
}

.App-link {
  color: #61dafb;
}

img {
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.card-container {
/*  position: absolute;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;  
*/
display:flex;
flex-direction:row;
justify-content: space-around;
flex-wrap: wrap;
}

.card {
  /* Add shadows to create the "card" effect */
  
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border: 2px solid #e7e7e7;
  border-radius: 4px;
  padding: .5rem;
  display:flex;
  flex-direction:column;
  flex: auto;
  width: 200px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

main {
  max-width: 38rem;
  padding: 2rem;
  margin: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
